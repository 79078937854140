<template >
    <div>
        <section id="pricing-plan">
            <!-- title text and switch button -->

            <h6 class="mt-2">Phoenixgn Package</h6>



            <div class="row">
                <div class="col-md-6">
                    <div class="card ecommerce-card">
                        <div class="item-img text-center">
                            <a href="">
                                <img class="img-fluid card-img-top" :src="phoenixgn_package.featured_logo"
                                    alt="img-placeholder" /></a>
                        </div>
                        <div class="card-body">
                            <div class="item-wrapper">

                                <div>

                                </div>
                            </div>
                            <h6 class="item-name">
                                {{ phoenixgn_package.title }}

                            </h6>
                            <p class="card-text item-description">
                                {{ phoenixgn_package.description }}

                            </p>
                        </div>
                        <div class=" card-footer item-options text-center">
                            <div v-if="userData.package_id" class="item-wrapper h5 text-white">
                              
                                ${{ format((phoenixgn_package.reg_fee - userData.package.reg_fee) / 500) }} <br>
                                to upgrade
                            </div>
                            <div v-else class="item-wrapper h5 text-white">
                              
                              ${{ format(phoenixgn_package.reg_fee / 500) }}
                          </div>


                        </div>

                    </div>
                </div>
                <div class="col-md-6">
                    <h6>Make Payment</h6>

                    <div class="accordion" id="accordionExample">
                        <div class="card ">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                        data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Pay via Paystack
                                    </button>
                                </h2>
                            </div>

                            <div v-if="!userData.package_id" id="collapseOne" class="collapse show"
                                aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <paystack :class="'btn btn-success '" :paystackkey="paystackkey"
                                        :split_code="'SPL_UMfLowpW9Z'"
                                        :channels="channels"
                                        :etf="etf"
                                        :ussd="ussd"
                                        :email="this.userEmail"
                                        :amount="phoenixgn_package.reg_fee * 100" :reference="reference"
                                        :onSuccess="onSuccessfulPayment" :onCanel="onCancelledPayment" :callback="callback"
                                        :close="close" :embed="false">Proceed to pay</paystack>
                                    <!-- <div v-else class="py-5 text-center">
                                        <h6>Already Subscribed.</h6>
                                    </div> -->
                                </div>
                            </div>

                            <div v-else class="py-3 text-center">
                                <h6>You are already on a package.</h6>
                                <paystack :class="'btn btn-warning '" :paystackkey="paystackkey"
                                    :split_code="'SPL_UMfLowpW9Z'" :email="this.userEmail"
                                    :amount="phoenixgn_package.reg_fee * 100" :reference="reference"
                                    :onSuccess="onSuccessfulPayment" :onCanel="onCancelledPayment" :callback="callback"
                                    :close="close" :embed="false">Upgrade</paystack>


                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left collapsed" type="button"
                                        data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                        aria-controls="collapseTwo">
                                        Pay Offline
                                    </button>
                                </h2>


                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    <div v-if="!userData.package_id" class="">

                                        <div class="row">
                                            <div class="col-6 d-none">
                                                <h6 class="">Bank Name:<br> First Bank Nig PLC</h6>
                                                <h6 class="">Account Name: <br>Kynch Renda Global LTD</h6>

                                                <h6 class="">Account Number: <br>2035174646</h6>

                                            </div>
                                            <div class="col-6">
                                                <div class="col-md-12 ">
                                                    <div class="form-group">





                                                        <img v-if="payment_proof_data"
                                                            onclick="document.getElementById('customFile').click()"
                                                            id="previewImg2"
                                                            style="height: 100px; width: 100px; object-fit: cover;"
                                                            class="shadow border border-primary "
                                                            :src="payment_proof_data.payment_proof">

                                                        <img v-else onclick="document.getElementById('customFile').click()"
                                                            id="previewImg2"
                                                            style="height: 100px; width: 100px; object-fit: cover;"
                                                            class="shadow border border-primary "
                                                            :src="'https://www.freshbooks.com/wp-content/uploads/proof-of-payment.jpg.optimal.jpg'">



                                                    </div>

                                                    <div class="text-center d-none">
                                                        <input @change="previewFile4" ref="file" type="file"
                                                            id="customFile">
                                                    </div>
                                                    <h6 class="">Upload Proof of Payment</h6>


                                                </div>
                                            </div>
                                        </div>

                                        <div class=" ">
                                            <div class="">


                                                <div class="col-md-12 ">

                                                    <div class="form-group d-none">
                                                        <button @click="uploadAvatar()"
                                                            class="btn-primary btn btn-sm ">Upload</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div v-if="payment_proof_data == null" class="form-group ">
                                            <button v-if="activating" class="btn btn-primary btn-block">Please
                                                wait...</button>
                                            <button v-else @click="register_payment_proof()"
                                                class="btn btn-primary btn-block">Activate Package</button>
                                        </div>
                                        <div v-else class="form-group col-md-6 mx-auto">

                                            <button v-if="payment_proof_data.status == 'approved'"
                                                class="btn btn-success btn-block" disabled>Approved</button>

                                            <button v-else class="btn btn-warning btn-block" disabled>Pending..</button>


                                        </div>


                                    </div>
                                    <div v-else class="py-5 text-center">
                                        <h6>Already Subscribed.</h6>

                                        <div class="row">
                                            <div class="col-6 d-none">
                                                <h6 class="">Bank Name:<br> First Bank Nig PLC</h6>
                                                <h6 class="">Account Name: <br>Kynch Renda Global LTD</h6>

                                                <h6 class="">Account Number: <br>2035174646</h6>

                                            </div>
                                            <div class="col-6">
                                                <div class="col-md-12 ">
                                                    <div class="form-group">





                                                        <img v-if="payment_proof_data"
                                                            onclick="document.getElementById('customFile').click()"
                                                            id="previewImg2"
                                                            style="height: 100px; width: 100px; object-fit: cover;"
                                                            class="shadow border border-primary "
                                                            :src="payment_proof_data.payment_proof">

                                                        <img v-else onclick="document.getElementById('customFile').click()"
                                                            id="previewImg2"
                                                            style="height: 100px; width: 100px; object-fit: cover;"
                                                            class="shadow border border-primary "
                                                            :src="'https://www.freshbooks.com/wp-content/uploads/proof-of-payment.jpg.optimal.jpg'">



                                                    </div>

                                                    <div class="text-center d-none">
                                                        <input @change="previewFile4" ref="file" type="file"
                                                            id="customFile">
                                                    </div>
                                                    <h6 class="">Upload Proof of Payment</h6>


                                                </div>
                                            </div>
                                        </div>

                                        <div class=" ">
                                            <div class="">


                                                <div class="col-md-12 ">

                                                    <div class="form-group d-none">
                                                        <button @click="uploadAvatar()"
                                                            class="btn-primary btn btn-sm ">Upload</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="form-group ">
                                            <button v-if="activating" class="btn btn-primary btn-block">Please
                                                wait...</button>
                                            <button v-else @click="register_payment_proof()"
                                                class="btn btn-warning btn-block"> Upgrade Package</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </section>


    </div>
</template>

<script>
import axios from 'axios'
import paystack from "vue-paystack";

// import { nanoid } from "nanoid"; //if using nanoid


export default {
    components: {
        paystack,
    },
    data() {
        return {
            phoenixgn_package: '',
            paystackkey: "pk_live_998b59bd6b59899cab4f39340777570b427aa263", //paystack public key
            email: "foobar@example.com", // Customer email
            amount: 1000000, // in kobo
            userData: '',
            package_id: '',
            channels: ["card", "bank", "ussd", "mobile_money", "qr"],
            selected_package_id: '',
            activating: false,
            payment_proof: '',
            payment_proof_data: '',
            userEmail: '',
            ussd: {
                "type": [""]
            },
            eft: {
                "provider": "ozow"
                 },
            // split_code: 'SPL_bMBJZkUdaq',
        }
    },

    computed: {
        reference() {
            let text = "";
            let possible =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (let i = 0; i < 10; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        },
    },



    methods: {

        previewFile4(event) {


            console.log(event)

            if (event.target.files.length > 0) {
                var src = URL.createObjectURL(event.target.files[0]);
                var preview = document.getElementById("previewImg2");
                preview.src = src;
                // preview.style.display = "block";
            }

            this.payment_proof = this.$refs.file.files[0];

        },

        onSuccessfulPayment: function (response) {

            console.log(response)

            axios({
                url: process.env.VUE_APP_URL + '/api/payment_callback',
                method: 'post',
                data: {

                    package_id: this.selected_package_id

                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {

                    console.log(response)


                    return this.$router.push('/Subscription-Success');


                })
                .catch((response) => {

                    console.log(response)
                })

        },

        onCancelledPayment: function () {
            console.log("Payment cancelled by user");
        },

        callback: async function () {

            // console.log()

            await axios({
                url: process.env.VUE_APP_URL + '/api/payment_callback',
                method: 'post',
                data: {

                    package_id: this.selected_package_id

                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },


            })
                .then((response) => {

                    console.log(response)

                    return this.goToSuccess()


                })
                .catch((response) => {

                    console.log(response)
                })



        },
        close: function () {
            console.log("Payment closed");
            // if (localStorage.getItem('reloaded')) {
            //     // The page was just reloaded. Clear the value from local storage
            //     // so that it will reload the next time this page is visited.
            //     localStorage.removeItem('reloaded');
            // } else {
            //     // Set a flag so that we know not to reload the page twice.
            //     localStorage.setItem('reloaded', '1');
            //     location.reload();
            // }
        },




        format(value) {
            var numeral = require('numeral');

            return numeral(value).format('N 0,0.00')
        },


        getPackage() {


            axios({
                method: "post",
                url: process.env.VUE_APP_URL + '/api/package',
                data: {
                    id: this.$route.params.id
                }
            })
                .then((response) => {

                    this.phoenixgn_package = response.data

                    this.selected_package_id = response.data.id

                    console.log(this.phoenixgn_package)
                })
                .catch((response) => {

                    console.log(response)
                })
        },
        async getUserProfile() {


            await axios({
                url: process.env.VUE_APP_URL + '/api/profiles',
                method: 'get',
                params: {
                    offline_payment: 1
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },
            })
                .then((response) => {

                    // alert('get profie')

                    this.userData = response.data

                    this.userEmail = this.userData.email

                    this.payment_proof_data = response.data.payment_proof

                    console.log(response)




                })
                .catch((response) => {


                    console.log(response)
                })


        },

        async getEmail() {

            return await this.userEmail
        },

        register_payment_proof() {

            // alert(this.phoenixgn_package.id)

            let formData = new FormData();

            formData.append('payment_proof', this.payment_proof);

            formData.append('type', 'proof');

            formData.append('user_id', this.userData.id);

            formData.append('package_id', this.phoenixgn_package.id);

            this.activating = true

            axios({
                method: "post",
                url: process.env.VUE_APP_URL + '/api/register_payment_proof',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                }
            }).then((response) => {

                this.activating = false

                this.$vToastify.success({
                    'body': '💸 Payment Proof Uploaded!!',
                    'iconEnabled': true,
                    'defaultTitle': false,
                    'hideProgressbar': true
                });




                console.log(response)
            })
                .catch((response) => {

                    console.log(response)
                })
        },

  



    },
    mounted() {
        this.getUserProfile()
        this.getPackage()


    },
}
</script>

